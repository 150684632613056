<template>
  <div class="manage">
    <header class="header">
      <div class="logo-wrap" @click="navToHome">
        <img class="logo" src="../../assets/logo.png" alt="logo">
      </div>

      <div class="user-wrap">
        <div class="user">
          <template v-if="!logined">
            <span class="login" @click="navToManageLogin">登录</span>
          </template>
          <div v-else>
            <span class="logout" @click="logout">退出登录</span>
            <el-dropdown>
              <!-- <span class="el-dropdown-link"> -->
                <img class="avatar" src="../../assets/user_avatar.png" alt="avatar">
              <!-- </span> -->
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item  @click="isKeyDialogShow = true">key 管理</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
      </div>
    </header>

    <div class="content-wrap">
      <aside class="aside">
        <ul class="aside-nav">
          <li :class="['nav-item', { active: route.path === '/manage/document' }]" @click="navTo('/manage/document')">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-falvfaguiwendang"></use>
            </svg>
            法律法规文档管理
          </li>
          <li :class="['nav-item', { active: route.path === '/manage/knowledge-base' }]" @click="navTo('/manage/knowledge-base')">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-promt"></use>
            </svg>
            知识库管理
          </li>
          <li :class="['nav-item', { active: route.path === '/manage/prompt' }]" @click="navTo('/manage/prompt')">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-promt"></use>
            </svg>
            Prompt 模板管理
          </li>
<!--          <li :class="['nav-item', { active: route.path === '/manage/model' }]" @click="navTo('/manage/model')">-->
<!--            <svg class="icon" aria-hidden="true">-->
<!--              <use xlink:href="#icon-promt"></use>-->
<!--            </svg>-->
<!--            model 模型管理-->
<!--          </li>-->
        </ul>
        <!-- <el-button class="key-btn" type="warning" @click="isKeyDialogShow = true">key 管理</el-button> -->
      </aside>

      <div class="main-content">
        <router-view></router-view>
      </div>
    </div>

    <Dialog v-model="isKeyDialogShow">
      <div class="key-box">
        <div class="title">key 管理</div>

        <div class="input-wrap">
          key：
          <input class="input" type="text" placeholder="请输入您的key" v-model="openAiKey">
        </div>

        <div class="submit-btn" @click="save" :loading="saveLoading">保存</div>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import { getConfiguration, setConfiguration } from '../../request/index'

import Dialog from '../../components/dialog.vue'

import { computed, ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from "vuex"

const store = useStore()
const route = useRoute()
const router = useRouter()

let logined = computed(() => store.state.token)
const logout = () => {
  store.commit('clearToken')
  router.push('/manage/login')
}

const navTo = (path) => {
  router.push(path)
}

const navToHome = () => {
  router.push('/')
}
const navToManageLogin = () => {
  router.push('/manage/login')
}

let configData = {}
const openAiKey = ref('')
const getConfigData = () => {
  getConfiguration().then(({ data }) => {
    configData = data.settings
    openAiKey.value = data.settings.openai_config.custom_open_ai_key
  })
}

const saveLoading = ref(false)
const save = () => {
  configData.openai_config.custom_open_ai_key = openAiKey.value
  console.log(configData)
  console.log({
    cmd: "setting",
    settings: configData
  })
  saveLoading.value = true
  setConfiguration({
    cmd: "setting",
    settings: configData
  }).then((res) => {
    getConfigData()
    isKeyDialogShow.value = false
  }).finally(() => {
    saveLoading.value = false
  })
}

onMounted(() => {
  getConfigData()
})

const isKeyDialogShow = ref(false)
</script>

<style lang="less" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  background: #383C47;
  box-sizing: border-box;
  padding: 0 1%;

  .logo-wrap {
    width: 120px;
    height: 28px;
    cursor: pointer;
    .logo {
      width: 98%;
      height: 98%;
    };
  }
  .user-wrap {
    display: flex;
    align-items: center;
    color: #FFFFFF;

    .user {
      display: flex;
      align-items: center;

      .login {
        cursor: pointer;
      }
      .sign-up {
        margin-right: 46px;
        cursor: pointer;
      }

      .avatar {
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }

      .logout {
        display: inline-block;
        vertical-align: top;
        margin: 6px 22px 0 0;
        cursor: pointer;
      }
    }
  }
}
.content-wrap {
  display: flex;
  height: calc(100% - 70px);

  .aside {
    position: relative;
    flex: 0 0 250px;
    height: calc(100vh - 70px);
    background: #49505d;
    .aside-nav {
      .nav-item {
        padding: 14px 0 14px 15%;
        color: #d1d1d1;
        cursor: pointer;

        .icon {
          margin-right: 8px;
        }
        &.active {
          background: #414854;
          color: #fff;
        }
      }
    }
    .key-btn {
      position: absolute;
      bottom: 10px;
      left: 10px;
      right: 10px;
      width: calc(100% - 20px);
    }
  }
  .main-content {
    flex: 1;
  }
}
.key-box {
  width: 300px;
  height: 250px;
  padding: 0 20px;
  box-sizing: border-box;

  .title {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #1d1e22;
    padding: 10px 0 20px;
    border-bottom: 1px solid rgba(213,213,213,0.35);
  }
  .input-wrap {
    margin-top: 36px;
    font-size: 14px;
  }
  input {
    width: 100%;
    height: 36px;
    outline: none;
    border-radius: 6px;
    padding: 0 6px;
    box-sizing: border-box;
    border: 1px solid #d5d5d5;
    border-radius: 6px;
    margin-top: 8px;
  }

  .submit-btn {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 6px;
    background: #f19135;
    color: #fff;
    box-sizing: border-box;
    margin-top: 36px;
  }
}
</style>